import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const StreamlineSection = () => (
  <section
    style={{
      background: "#f3f3f3",
      padding: "3rem 0",
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h2>Streamline EVERYTHING with Quickbase</h2>
        </div>
      </div>
      <div
        className="row align-items-center mt-lg-5 mt-md-0 mt-3 flex-lg-row flex-md-row flex-column-reverse"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px",
          marginTop: "3rem",
        }}
      >
        <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile">
          <h3 className="mt-0">Clear Data Dashboarding</h3>
          <p className="mt-4">
            Connect all your applications and gather your data into one, easy to
            access portal for complete transparency.
          </p>
        </div>
        <div className="col-lg-6 col-md-6 py-lg-3 py-3">
          <StaticImage
            placeholder="blurred"
            imgClassName="rounded d-flex"
            src="../../images/new-images/quick-base-solutions-img/quick-base-img1.png"
            alt="Integrate Data Faster"
            layout="constrained"
            objectFit="contain"
            loading="lazy"
          />
        </div>
      </div>
      <div className="row align-items-center mt-3">
        <div className="col-lg-6 col-md-6 py-lg-4 py-3">
          <StaticImage
            placeholder="blurred"
            imgClassName="rounded d-flex"
            src="../../images/new-images/quick-base-solutions-img/quick-base-img2.png"
            alt="Easy to use"
            layout="constrained"
            objectFit="contain"
            loading="lazy"
          />
        </div>
        <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
          <h3 className="mt-0">Easy to Use</h3>
          <p className="mt-4">
            A visual interface, free from code, makes it easy for anyone to drag
            and drop seamless integrations.
          </p>
        </div>
      </div>
      <div
        className="row align-items-center flex-lg-row flex-md-row flex-column-reverse mt-3"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px",
        }}
      >
        <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-5 px-md-5 text-left-mobile">
          <h3 className="mt-0">Complete Process Automation</h3>
          <p className="mt-4">
            Connect all of your business processes. Track accurate data for your
            team. Use insights to grow your business.
          </p>
        </div>
        <div className="col-lg-6 col-md-6 py-lg-3 py-3">
          <StaticImage
            placeholder="blurred"
            imgClassName="rounded d-flex"
            src="../../images/new-images/quick-base-solutions-img/quick-base-img3.png"
            alt="Complete Process Automation"
            layout="constrained"
            objectFit="contain"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </section>
)

export default StreamlineSection
